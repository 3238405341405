// ==============================================================
// COMPONENTS => CODEPEN CARDS
// ==============================================================

$pen-transition:    675ms ease-in-out;

.wep-pen {
  height: 300px;
  margin-bottom: 40px;
  &:last-of-type { margin-bottom: 0; }
  overflow: hidden;
  @media (min-width: 960px) { margin-bottom: 0; }
  transition: $pen-transition;

  &-avatar {
    & .uk-border-circle {
      background-color: #fff;
      border: 4px solid transparent;
      box-shadow: inset 0 0 0 2px transparent;
      width:40px;
      height: 40px;
      padding: 6px;
      margin: 15px;
      transition: $pen-transition;
      // transition: all 800ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
      // transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    }

    & img {
      fill: #222;
      transition: $pen-transition;
    }
  }

  &-header {
    transform: translateY(0);
    transition: all 450ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
  }

  &-title {
    @extend .wep-project-title;
    color: #fff;
    margin-bottom: 0;
    text-decoration: none;
    // transition: $pen-transition;
  } // end title

  &-image-container {
    @extend .wep-project-image-container;
    height: 100%;
    margin-bottom: 0;
    transition: $pen-transition;
    // @media (min-width: 960px) { height: 300px; }
  }

  // -----------------------------------
  // ## BACKGROUND IMAGE
  &-image {
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;

    &:before {
      content: "";
      background-image: linear-gradient(to top, rgba(0,0,0,0.6), rgba(0,0,0,0) 100%);
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
    }
  } // end bg

  &-footer {
    @extend .wep-project-body;
    transform: translateY(1000%);
    // transition: $pen-transition;
    transition: all 400ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);

    & a, & a:link, & a:visited {
      color: #fff;
      padding-bottom: 4px;

      &:before, &:after {
        border-color: #fff;
      }

      &:before {
        right: 0;
      }

      &:after {
        right: 100%;
      }

      &:hover, &:focus {
        &:before {
          left: 100%;
        }

        &:after {
          right: 0;
        }
      }
    }
  }

  &:hover, &:focus {
    & .wep-pen-avatar {
      & .uk-border-circle {
        // box-shadow: inset 0 0 0 2px #499de1;
      }
      & img {
        fill: #499de1;
      }
    }

    & .wep-pen-header {
      transform: translateY(-40px);
    }

    & .wep-pen-footer {
      transform: translateY(-120%);
    }

    & .wep-pen-image-container {
      // box-shadow: $shadow-material-depth-2;
    }
  }
} // end wep-pen
