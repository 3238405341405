// ======================================================
// # SLIDEOUTjs
// https://github.com/mango/slideout
// ======================================================

body {
  width: 100%;
  height: 100%;
}

.slideout-menu {
  background: #f8f8f8;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 256px;
  min-height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 0;
  display: none;
}

.slideout-menu-left {
  left: 0;
}

.slideout-menu-right {
  right: 0;
}

.slideout-panel {
  position: relative;
  z-index: 1;
  // will-change: transform;
  background-color: #fff;
  min-height: 100vh;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}

.slideout-open .slideout-menu {
  display: block;
}

// @media screen and (min-width: 780px) {
//   .slideout-panel {
//     margin-left: 256px;
//   }
//
//   .slideout-menu {
//     display: block;
//   }
//
//   .btn-hamburger {
//     display: none;
//   }
// }

#panel:before {
  content: "";
  display: block;
  background-color: rgba(0,0,0,0);
  transition: background-color 0.5s ease-in-out;
}

#panel.panel-open:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0,0,0,.5);
  z-index: 99;
}

#menu {
  & .uk-text-small {
    font-family: sans-serif;
  }

  & .uk-navbar-center,
  & .uk-navbar-nav {
    width: 100%;
  }

  & .uk-navbar-nav {
    display: list-item;

    & li {
      border-bottom: 1px solid #ddd;
      &:first-child { border-top: 1px solid #ddd; }
    }
  }

  & .wep-socials {
    margin-left: 0;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    // & li { width: 33.33%; }
  }

  & a, & a:link, & a:visited {
    &:before, &:after { content: none; }
  }
}
