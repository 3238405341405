// ==============================================================
// GRID
//
// Styles and adjustments for the site's grid system.
// ==============================================================

.wep-container {
  max-width: 740px;
  padding: 0 20px;
  box-sizing: border-box;
}

// .wep-article {
//   & .content {
//     & h3, & h4, & h5, & h6,
//     & p, & dl, & blockquote {
//       max-width: 740px;
//       padding: 0 20px;
//       margin: 0 auto 20px;
//       box-sizing: border-box;
//     }
//   }
// }

@media (min-width:2000px) {
  .uk-width-1-5\@xxl { width: calc(100% * 1 / 5.001); }
}

@media (min-width:2400px) {
  .uk-width-1-6\@xxxl { width: calc(100% * 1 / 6.001); }
}