// ==============================================================
// FOOTER
//
// Styles and adjustments for the site's primary desktop footer
// ==============================================================

.pansky-social {
  & li { padding: 0; }
  & a {
    padding: 24px;
  }
}

footer {
  & .uk-text-small {
    font-family: sans-serif;
  }
}
