// ======================================================
// # RESETS
//
// Tackling the hard stuff, lol
// ======================================================

html {
  max-width: 100vw;
  overflow-x: hidden;
  // overflow-y: scroll;
}

.uk-offcanvas-container,
.uk-offcanvas-page {
  overflow: hidden;
}

.uk-offcanvas-overlay::before {
  background: rgba(0,0,0,.75);
}

.noscroll {
  overflow-y: hidden !important;
  // height: 100vh !important;
  // max-height: 100vh !important;
}

// .uk-child-width-1-3\@l > * {
//   width: 33.33% !important;
// }

// body {
//   &::-webkit-scrollbar {
//     width: 10px;
//   }
// }
//
// body::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
// }
//
// body,
// #offcanvas {
//   &::-webkit-scrollbar-thumb {
//     background-color: $color-accent;
//     outline: 1px solid $color-accent;
//   }
// }
