// ======================================================
// # STICKY
// ======================================================

.wep-sticky-header {
  background: #fff;

  &.uk-active {

  }
}

.wep-article .content {
  & h4.uk-sticky.uk-active { font-size: .875rem; }
  & .uk-sticky.uk-active {
    margin: inherit;

    @media (min-width: 960px) {
      background-color: #fff;
      width: 132px !important;
      position: fixed;
      top: 0;
      left: 0;
      padding: 20px;
    } // end @960

    @media (min-width: 1200px) { width: 200px !important; }
    @media (min-width: 1440px) { width: 400px !important; }
  } // end sticky active
} // end article content
