// ==============================================================
// PROJECTS => SINGLE PROJECT
// ==============================================================

.wep-article {
  & h3:not(:first-of-type) {
    margin-top: 60px;
  }

  & h4 {
    font-size: 1.15rem;

    &:not(:first-of-type) {
      margin-top: 48px;
    }
  }

  & .top {
    & p { margin: 0; }
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
  }

  & .avatar {
    display: block;
    vertical-align: middle;
    border-radius: 100%;
    width: 60px;
    height: 60px;
  }

  & .client {
    font: normal 13px/1.4 sans-serif;
    letter-spacing: .065px;
    padding-left: 15px;

    & p:first-child {
      color: #000;
    }

    & p:last-child {
      color: #595959;
    }
  }

  & .info {
    font: normal .875rem/1.4 sans-serif;
    letter-spacing: .065px;
  }

  & .hero {
    width: 100%;
  }

  & .caption,
  & .caption > figcaption > h4 {
    color: #595959;
    font: normal 14px/1.25 sans-serif;
    letter-spacing: .265px;
    text-align: center;
    display: block;
    margin-top: 8px;
  }

  & .caption {
    & a,
    & a:link,
    & a:visited {
      // remove underlines
      &:before, &:after { content: none; }
    }

    & > img {
      width: 100%;
      max-width: 90vw;
      margin: auto;
      transform: translateX(0);
      transition: 100ms ease-in-out;
      // transition: all 100ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
      // transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    }
  }

  & .uk-accordion-title {
    font-family: sans-serif;
    transition: 200ms ease-in-out;
    &:hover, &:focus {
      opacity: .45;
    }
  }
} // end -article

.styleguide {

  &-colors {
    font-family: sans-serif;
    font-weight: bold;
    list-style-type: none;
    // display: flex;
    // flex-direction: row;
    // flex-wrap: wrap;
    // align-items: flex-start;
    // justify-content: space-between;
    padding: 0;
    margin: 0 0 1rem;

    & ul { padding: 0; }

    & li {
      // &:first-of-type {
      //   min-width: 150px;
      //   min-height: 150px;
      // }
      // min-width: 100px;
      // min-height: 100px;
      // margin-right: 20px;
      &:last-of-type { margin-right: 0; }
    }

    & #colorsPrimary { background: #0e75dd; }
    & #colorsPrimaryLight1 { background: mix(white, #0e75dd, 15%); }
    & #colorsPrimaryLight2 { background: mix(white, #0e75dd, 35%); }
    & #colorsPrimaryDark1 { background: mix(black, #0e75dd, 15%); }
    & #colorsPrimaryDark2 { background: mix(black, #0e75dd, 35%); }

    & #colorsAccent { background: #f49200; }
    & #colorsAccentLight1 { background: mix(white, #f49200, 15%); }
    & #colorsAccentLight2 { background: mix(white, #f49200, 35%); }
    & #colorsAccentDark1 { background: mix(black, #f49200, 15%); }
    & #colorsAccentDark2 { background: mix(black, #f49200, 35%); }

    & #colorsBlack { background: #222; }
    & #colorsGray1 { background: #666; }
    & #colorsGray2 { background: #959595; }
    & #colorsWhite1 { background: #e5e5e5; color: #666; }
    & #colorsWhite2 { background: #fdfdfd; color: #666; }
  }

} // end styleguide





// .wep-modal-full {
//   & .uk-close {
//     background-color: $color-accent;
//     & svg {
//       color: #fff;
//     }
//   }
// } // end wep-modal-full
