// ==============================================================
// HEADER
//
// Styles and adjustments for the site's primary desktop header
// ==============================================================

.pansky-nav {
  width: 100%;
  @media (min-width: 960px) { width: auto; }
  max-height: 80px;
  overflow: hidden;
  z-index: 2;
}

// footer.pansky-project {
//   @media (min-width: 960px) { margin-left: 240px; }
// }
