// ==============================================================
// COMPONENTS => ACCORDION
// ==============================================================

.wep-accordion-fullscreen {
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow: visible;

  &-title {
    font-size: 1.25rem;
    line-height: 1.4;
    font-weight: normal;
    padding: 8px 0;
    margin: 0 auto;
    overflow: hidden;
    cursor: pointer;
  }

  &-content-wrapper {
    // background: #ccc;
  }

  &-content {
    // height: 0;
    // opacity: 0;
    display: none;
    width: 100%;
    max-width: 900px;
    margin: 0 auto 1rem;
    // position: relative;
    // z-index: -1;
    padding: 60px 20px;

    & .wep-afs-item {
      // opacity: 0;
    }

    & a {
      // color: #fff;
    }
  } // end -content

  & .wep-active {
    & .wep-accordion-fullscreen-content {
      // z-index: 0;
    }
  }
} // end accordion-fullscreen
