// ==============================================================
// RESUME
// ==============================================================

.pansky-resume {
  // & h2 {
  //   font-size: 1.5rem;
  // }

  & .uk-text-meta {
    font-family: sans-serif;
  }

  &-skillslist {
    & dd {
      font-size: .875rem;
      display: inline;

      &:after {
        content: "|";
        color: $color-accent;
        margin: 0 4px 0 8px;
        // font-size: 1rem;
        font-family: sans-serif;
      } // end :after

      &:last-child:after {
        content: none;
      }
    } // end dd
  } // end skills-list

  &-sublist li {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 10px;
      left: -20px;
      width: 10px;
      border-top: 1px solid #bfbfbf;
    }
  } // end sublist

} // end .pansky-resume

.wep-resume {
  & h4 {
    margin: 0 auto 4px;
  }

  & p {
    font-family: sans-serif;
    font-size: .75rem;
    line-height: 1.5;
    margin: 0 auto;
  }

  & > ul {
    list-style-type: none;
    padding: 0;
    margin-top: 1.5rem;
  }

  & li {
    font-size: .875rem;
    line-height: 1.5;
    border-bottom: 1px solid #ddd;
    &:last-child { border-bottom: 0; }
    padding: 0 0 .5rem;
    margin: 0 auto .5rem;
  }
}
