// ======================================================
// # COMPONENT => NAVS
// ======================================================
#mainNav {
  background-color: $color-accent;

  & a, & a:link, & a:visited {
    color: #fff;
    &:hover, &:focus {
      color: $color-primary;
      background-color: #fff;
    }
  } // end a

  & .uk-navbar-left {
    width: 100%;
    text-align: center;
    @media (min-width: 640px) {
      width: auto;
      text-align: left;
    }
  }

  & .uk-navbar-item {
    letter-spacing: .075px;
    margin: 0 auto;
  }
} // end #mainNav

.uk-navbar-nav > li > a {
  color: $color-accent;
  &:hover, &:focus {
    color: $color-primary;
  }
}

.uk-iconnav > * {
  padding-left: 0;
  margin-left: 0;
}

#wep-bar {
  font-size: 14px;
  position: fixed;
  right: 0; bottom: 0; left: 0;
  z-index: 100;
  width: 100%;
  height: 44px;
  background: #fff;
  padding: 0 0 1px;
  box-sizing: border-box;
  box-shadow: 0 0 1px rgba(0,0,0,.44);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  & a, & a:link, & a:visited {
    &:before, &:after { content: none; }
  }

  & .left, &.right {
    transition: all 600ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
  }

  & .left {
    font-family: sans-serif;
    text-align: left;
    margin-left: 20px;

    & a {
      padding: 8px;
    }
  }

  & .right {
    font-family: "Lato", sans-serif;
    text-align: left;
    // width: 50%;
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;

    & .uk-iconnav {
      float: left;
    }

    & .next {

      & span {
        color: #595959;
        font-size: 12px;
        font-weight: bold;
        margin: 0;
        display: block;
        line-height: 1.4;
      }

      & p.uk-truncate {
        // color: rgba(0,0,0,.8);
        // color: #007dbc;
        font-size: 14px;
        font-weight: bold;
        margin: -3px 0 0;
        line-height: 1.2;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &:hover, &:focus {
        color: inherit;
      }
    } // end next
  } // end right

  & .heart {
    margin-right: 8px;
    &-text { color: #777; }
  }

  & .convo {
    color: #777;
  }

  // https://matthewlein.com/ceaser/
  // easeInOutQuint
  transition: all 600ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
  transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);

  &.scrolling {
    transform: translate3d(0,110%,0);
  }

  &.wep-open {
    transform: translateY(-92.5vh);
  }

  &.ios.scrolling {
    transform: translate3d(0,0,0);
  }
} // end #wep-bar

#wep-bar .right .next { width: 50%; }
#wep-bar .right .next.navi {
  width: 100%;
  & .wep-navbar .uk-navbar-nav > li > a {
    font-size: 14px;
    height: auto;
  }
}

#readingProgress {
  content: "";
  height: 2px;
  position: fixed;
  left: 0;
  z-index: 900;
  width: 0%;
  // background: $color-accent;
  background-image: linear-gradient( 135deg, #FFF6B7 0%, #F6416C 100%);
}

#mainNav {
  & .uk-navbar-item,
  & .uk-navbar-nav > li > a,
  & .uk-navbar-toggle {
    height: 44px;
  }

  & .uk-navbar-nav > li > a {
    font-size: 13px;
    font-weight: 500;
  }

  & .uk-logo {
    font-size: 20px;
  }

  & a, & a:link, & a:visited {
    transition: all 600ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    &:before, &:after { content: none; }
  }
}

#tableContents {
  font-size: 14px;
  position: fixed;
  right: 0; bottom: 0; left: 0;
  z-index: 100;
  width: 100%;
  height: 0;
  background: #fff;
  padding: 0;
  box-sizing: border-box;
  box-shadow: 0 0 1px rgba(0,0,0,.44);
  transition: all 600ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
  transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);

  &.wep-open {
    height: 93vh;
  }
}
