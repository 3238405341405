// ==============================================================
// COMPONENTS => BUTTONS
// ==============================================================

.wep-button {
  font-family: "Lato", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  transition: 200ms ease-in-out;
}

.uk-button-default {
  color: #666;
  &:hover, &:focus {
    color: #222;
    background-color: transparent;
    border-color: $color-primary;
  }
}

input#submit.uk-button.uk-button-primary {
  @extend .wep-button;
  background-color: $color-accent;
  &:hover, &:focus {
    background-color: $color-primary;
  }
}

input#reset.uk-button {
  @extend .wep-button;

  &:hover, &:focus {
    // background-color: $color-primary;
  }
}

.wep-button-icon {
  padding: 0 20px 0 28px;
  & svg {
    position: relative;
    bottom: 1px;
  }

  &:hover, &:focus {

  }
}
