// =============================================
// LOADER ITEMS
// =============================================
#loader {
  position: relative;
  z-index: 200;
}

#fader {
  content: "";
  transition: 600ms ease-in-out;
  width: 100%;
  min-width: 100vw;
  min-height: 100vh;
  position: absolute;
  top: 0; right: 0; left: 0; bottom: 0;
  z-index: 150;
  background: #fff;
  opacity: 1;
}
