// ======================================================
// # LINKS
// ======================================================
// ## LINKS => Remove underline
a,
a:link,
a:visited {
  color: $color-accent;
  text-decoration: none;
  position: relative;
  // transition: 300ms ease-in-out;
  transition: all 400ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
  transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);

  &:before {
    content: "";
    border-bottom: 2px solid #49a7f3;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    // transition: 600ms ease-in-out;
    transition: all 600ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
  } // end :before (underline)

  &:after {
    content: "";
    border-bottom: 2px solid $color-primary;
    position: absolute;
    bottom: 0;
    right: 100%;
    left: 0;
    overflow: hidden;
    // transition: 300ms ease-in-out;
    transition: all 300ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
  } // end :before (underline)

  &:hover, &:focus {
    color: $color-primary;
    text-decoration: none;

    &:before {
      left: 100%;
      // transition: 200ms ease-in-out;
      transition: all 200ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
      transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    } // end hover > :before (underline)

    &:after {
      right: 0;
      // transition: 400ms ease-in-out;
      transition: all 400ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
      transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    } // end hover > :after (underline)
  } // end hover
} // end a

.pansky-link-external {
  position: relative;
  &:after {
    content: "*";
    font-size: 80%;
    position: absolute;
    top: 0;
    right: -5px;
  }
}
