// ==============================================================
// COMPONENTS => RESUME EXPERIENCE LIST
// ==============================================================

.wep-resume-exp {
  // padding: 10px;

  &-title {
    font-size: 1rem;
    font-weight: normal;
    letter-spacing: normal;
    margin: 0 0 .325rem;
  }

  &-company,
  &-date,
  &-geo {
    line-height: 1.275;
    padding: 0;
    margin: 0;
  }

  &-company {
    font-size: .815rem;
    font-weight: bold;
  }

  &-date,
  &-geo {
    font-family: sans-serif;
    font-size: .675rem;
  }
} // end project
