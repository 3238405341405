// ==============================================================
// COMPONENTS => PROJECTS LIST
// ==============================================================

.wep-projects-list {

} // end project-list

// -----------------------------------------
// # PROJECTS
.wep-project {
  position: relative;
  transition: 200ms ease-in-out;

  // -----------------------------------------
  // ## PROJECTS => CLIENT
  &-client {
    width: 100%;
    font-size: 12px;
    line-height: 1.25;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 16px;

    &-avatar {
      border-radius: 50%;
      background-size: cover;
      background-position: center center;
      width: 30px;
      height: 30px;
      flex: 0 0 auto;
      align-self: flex-start;
      margin-right: 8px;
    }

    &-info {
      letter-spacing: .25px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    &-info-top {
      color: #595959;
      font-family: sans-serif;
      text-transform: uppercase;
    }

    &-info-bottom {
      color: #222;
      font-family: "Verdana", sans-serif;
    }
  }  // end -client

  // -----------------------------------------
  // ## PROJECTS => IMAGE
  &-image-container {
    margin-bottom: 16px;
    overflow: hidden;
    transition: all 750ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);

    height: 150px;
    @media (min-width: 412px) { height: 200px; }

    &.large {
      @media (min-width: 640px) { height: 350px; }
    }
  }

  &-image {
    // background-color: $color-accent;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 0 0 0 rgba(255,255,255,.45);
    transition: all 600ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    // transition: 300ms ease-in-out;

    &:hover, &:focus {
      // box-shadow: inset 0 0 0 10px rgba(255,255,255,.45);
      // transform: scale(1.050);
    }
  }

  &-image-fullwidth {
    background-color: #fff;
    box-shadow: none;
    transition: initial;
  }

  // -----------------------------------------
  // ## PROJECTS => HEADER
  &-header {
    margin-bottom: 8px;
  }

  // -----------------------------------------
  // ## PROJECTS => TAG
  &-category {
    padding: 0;
    margin: 0 0 6px;
    color: #333;
    border-bottom: 1px solid #555;
    font-family: sans-serif;
    font-size: 10px;
    line-height: 1.5;
    display: inline-block;
    letter-spacing: 2px;
    text-transform: uppercase;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // -----------------------------------------
  // ## PROJECTS => TITLE
  &-title {
    // font-family: sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.25;
    padding: 0;
    margin: 0 0 8px;

    & a, & a:link, & a:visited {
      color: #333;
      text-decoration: none;
      transition: 300ms ease;

      &:before, &:after {
        content: none;
      }

      &:hover, &:focus {
        color: $color-accent;
      } // end hover
    } // end a
  } // end -title

  &-name {
    // color: #595959;
    font: normal 15px/1.4 sans-serif;
    margin: 0;
  }

  // -----------------------------------------
  // ## PROJECTS => BODY
  &-body {
    font-family: sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.45;
    margin-bottom: 20px;
    overflow: hidden;

    & a,
    & a:link,
    & a:visited {
      &:before { right: 8px; }
      &:hover, &:focus {
        &:after { right: 8px; }
      }
    }
  }

  // -----------------------------------------
  // ## PROJECTS => DETAILS
  &-details {
    font-family: sans-serif;
    font-size: 12px;
  }

  // -----------------------------------------
  // ## PROJECTS => BUTTON
  &-button {
    padding: 0 20px 0 24px;
  }

  // -----------------------------------
  // ## PROJECTS => FULL-WIDTH INFO WRAPPER
  .wep-project-image-fullwidth {
    position: relative;

    &:after {
      // content: "";
      background-color: rgba(255,255,255,.765);
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      height: 100%;
      min-height: 100%;
      max-height: 100%;
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
    }

    @media(min-width: 640px) {
      &:after { content: none; }
    }
  }

  // -----------------------------------
  // ## PROJECTS => FULL-WIDTH INFO LIGHT BG
  .wep-project-fullwidth-info-light-bg {
    background: rgba(255,255,255,.925);
    border: 1px solid rgba(255,255,255,.915);
  }
} // end project

// -----------------------------------
// ## HOVERS
.wep-project {
  &:hover, &:focus {

    & .wep-project-image {
      box-shadow: inset 0 0 0 10px rgba(255,255,255,.45);
      // box-shadow: inset 0 0 0 10px rgba(0,118,214,.365); // accent
    }

    & .wep-project-image-fullwidth {
      box-shadow: none;
    }
  }
} // end project hovers
