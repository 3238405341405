// ==============================================================
// DISPLAY
// Add the class to your element according to the display you want.
// ==============================================================
.wep-display {

  // Block
  &-block {
    display: block !important;
  }

  // Flex
  &-flex {
    display: flex !important;
  }

}



.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0
}
