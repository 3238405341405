// ==============================================================
// BORDER STYLES
// ==============================================================

.pansky-border {

  &-muted {
    border: 1px solid $color-gray-light; 
  }
}
