// ======================================================
// # PROJECT => MILLER.AGENCY WORDPRESS SITE
// ======================================================

img[alt="UIkit"] {
  max-width: 150px;
  margin: 0 auto;
  text-align: center;
}

#metroCard {
  color: #595959;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;

  .elementor-widget-wrap {
	  z-index: auto !important;
    position: relative;
    width: 100%;
  }

  .elementor-widget {
    position: relative;
  }

  .elementor-widget:not(:last-child) {
    margin-bottom: 20px;
  }

  .elementor-column {
    position: relative;
    min-height: 1px;
    display: -ms-flexbox;
    display: flex;
  }

  .maa-metro-tile .elementor-flip-box {
    cursor: pointer!important;
  }

  .elementor-flip-box {
    height: 280px;
    position: relative;
    -webkit-perspective: 1000px;
    perspective: 1000px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }

  .elementor-flip-box__front {
    background-image: url(/projects/miller.agency/demo/wp-content/uploads/MAA-Home-Digital.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .elementor-flip-box__front {
    background-color: #4054b2;
    z-index: 2;
  }

  .elementor-flip-box__back {
    background-image: url(/projects/miller.agency/demo/wp-content/uploads/MAA-Home-Digital.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .elementor-flip-box--effect-fade .elementor-flip-box .elementor-flip-box__back {
    opacity: 0;
  }

  .link {
    cursor: pointer;
    color: #0e75dd;
  }

  .elementor .link {
    box-shadow: none;
    text-decoration: none;
  }

  .elementor-flip-box__layer {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all .4s ease-in-out;
  }

  .elementor-flip-box__back {
    background-color: #4054b2;
    display: block;
    z-index: 1;
  }

  .elementor-flip-box__front .elementor-flip-box__layer__overlay
  .elementor-flip-box__back .elementor-flip-box__layer__overlay {
    padding: 20px 250px 20px 20px;
  }

  .elementor-flip-box__back .elementor-flip-box__layer__overlay {
    background-color: rgba(14,117,221,0.9);
    text-align: left;
    justify-content: flex-end;
  }

  .elementor-flip-box__layer__overlay {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    text-align: center;
    padding: 35px;
    box-sizing: border-box;
  }

  .elementor-flip-box__front .elementor-flip-box__layer__overlay {
    background-color: rgba(0,0,0,0.55);
    text-align: left;
    justify-content: flex-end;
  }

  h3.elementor-flip-box__layer__title {
    font-size: 24px;
    font-family: 'Helvetica Neue',Arial,sans-serif;
    font-weight: 400;
    color: #fff;
    padding: 0;
    margin: 0 0 4px !important;
  } // end title

  .elementor-flip-box__layer__description {
    font-size: 16px;
    font-family: sans-serif;
    font-weight: 300;
    line-height: 1.1;
    color: #d4d3d3;
    padding: 0;
    margin: 0;
  }

  .elementor-flip-box__button {
    text-transform: uppercase;
  }

  .elementor-flip-box {
    cursor: pointer !important;
    overflow: hidden;
    @media (max-width:767px) { height: 240px !important; }
  }

  .elementor-widget-flip-box .elementor-flip-box .elementor-flip-box__button.elementor-button {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 1;
    visibility: hidden;
    transition: .3s ease-in-out;
}

.elementor-54359 .elementor-element.elementor-element-il49ppl .elementor-flip-box__button {
    margin-left: 0;
}

.elementor-widget-flip-box .elementor-flip-box__button.elementor-button {
    position: absolute;
    top: 0;
    right: 0;
    margin: 16px;
    border-width: 1px;
    border-radius: 0;
}

.maa-metro-tile .elementor-flip-box__button {
    text-transform: uppercase;
}

.elementor-flip-box__button.elementor-button {
    cursor: pointer;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid #fff;
    color: #fff;
    background: transparent;
}

.elementor-button.elementor-size-sm {
    font-size: 15px;
    padding: 12px 24px;
    border-radius: 3px;
}

.elementor-button {
    display: inline-block;
    line-height: 1;
    background-color: #818a91;
    color: #fff;
    text-align: center;
    transition: all .5s;
}

.elementor *, .elementor :after, .elementor :before {
    box-sizing: border-box;
}

  // -----------------------------------------
  // ## add "text-shadow" class to add a slight drop shadow on
  // the front of text before hover
  .text-shadow {
  	& .elementor-flip-box__layer.elementor-flip-box__front {
  		& h2, & h3, & h4, & h5, & h6, & p {
  			text-shadow: 1px 1px 1px rgba(0,0,0,.25);
  		}
  	}
  }

  .elementor-flip-box__button.elementor-button {
		// animation-name: stylie-keyframes;

    &:hover, &:focus {
      color: #303030;
      border-width: 1px;
      background-color: #fff;
    }
  }

  .elementor-flip-box__button.elementor-button {
    // animation-name: stylie-keyframes;
    animation-duration: 500ms;
    animation-delay: 0ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-iteration-count: once;
    transform-origin: 0 0;
  }

  @keyframes stylie-keyframes {
    0% {
  		transform: translateY(-100px);
  	}
  	50% {
  		transform: translateY(18px);
  	}
  	75% {
  		transform: translateY(-6px);
  	}
  	100% {
  		transform: translateY(0);
  	}
  }

  @media (max-width: 1024px) {
  .elementor-flip-box__front .elementor-flip-box__layer__overlay,
  .elementor-flip-box__back .elementor-flip-box__layer__overlay {
      padding: 10px 140px 20px 20px;
    }
  }

  &:hover, &:focus {

    & .elementor-flip-box__front {
      opacity: 0;
      z-index: 1;
    }

    & .elementor-flip-box__back {
      opacity: 1 !important;
      z-index: 2;
    }

    & .elementor-flip-box__button.elementor-button {
      animation-name: stylie-keyframes;
      visibility: visible !important;
      opacity: 1 !important;
      z-index: 4;
    }
  }

} // end #metroCard

#maaType {
  & h1 {
    font-family: 'Helvetica Neue', Arial, sans-serif !important;
		font-weight: bold !important;
    color: #333 !important;
    text-transform: none !important;
		line-height: 1 !important;
		letter-spacing: -1.5px !important;
    font-size: 3rem !important;
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
    word-break: break-word !important;
    hyphens: auto !important;

    @media (min-width:640px) { font-size: 5rem !important; }
    @media (min-width:960px) { font-size: 5.5rem !important; }
    @media (min-width:1024px) { font-size: 6rem !important; letter-spacing: -2px !important; }
    @media (min-width:1600px) { font-size: 8rem !important; }
	} // end h1

  & h2 {
    font-family: 'Helvetica Neue', Arial, sans-serif !important;
    color: #333 !important;
    text-transform: none !important;
    opacity: .65;
    line-height: 1.1 !important;
    font-weight: 300 !important;
    letter-spacing: .5px !important;
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
    word-break: break-word !important;
    hyphens: auto !important;
	} // end h2

  & p {
    font: normal 16px/1.5 sans-serif;
    background: none;
    margin: 0;
    border: none;
    padding: 0;


    & span {
      position: relative;
      cursor: pointer;
      transition: .3s ease-in-out;
      color: #0e75dd;
      text-decoration: none;
      overflow: visible;
      display: inline-block;
      box-sizing: border-box;
      transition-property: color,background-color,border-color;

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-bottom: 1px solid #0e75dd;
        transition: .4s ease-out;
      }

      &:hover, &:focus {
        color: #303030;
        text-decoration: none;

        &:before {
          right: 100%;
          left: 100%;
        }
      }
    }
  } // end p
} // end miller typw
