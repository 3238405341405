// ==============================================================
// COMPONENTS => PORTFOLIO LIST
// ==============================================================

.wep-portfolio {
  // padding: 10px;

  &-avatar .uk-border-circle {
    border: 0;
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 15px;
  }

  &-tag {

  }

  &-title {
    font-size: 1rem;
    font-weight: normal;
    letter-spacing: normal;
  }

  &-details {
    opacity: 0;
    display: none;
  }
} // end portfolio
