// ==============================================================
// COMPONENTS => SOCIALS
// ==============================================================
.wep-socials {

  & a,
  & a:link,
  & a:visited {
    // remove border-bottom link style from gist links
    &:before, &:after { content: none; }

    &:hover, &:focus {
      transform: translateY(-4px);
    }
  } // end a

  &.uk-iconnav {
    margin-left: -15px;
    @media (min-width: 1200px) { margin-left: auto; margin-right: auto; }
  }
} // end socials
