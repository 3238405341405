// ==============================================================
// HOMEPAGE
// ==============================================================

#wep-profile-image {
  background-image: url('/pansky.jpg');
  background-repeat: no-repeat;
  background-position: center;
  height: 50vh;
  width: 100%;
}
