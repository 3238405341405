// ==============================================================
// COMPONENTS => CODE
// Code tags & Hugo Gist shortcode styles
// ==============================================================
// ## CODE => <pre>
pre {
  color: inherit;
  background: $atom-base;
  border: 0;
  border-radius: 0;
  padding: 0;
  // margin: 0 auto;

  & .hljs {
    font-size: 14px;
    padding: 20px;
  }

  & .pansky-link-external {
    color: #fff;
    text-decoration: underline;
    &:hover, &:focus {
      text-decoration: none;
    }
  }

  & a {
    @extend .pansky-link-external;
    & span { color: #fff; }
  }

  & .uk-modal-dialog {
    background: $atom-base;
  }

  & .uk-modal-body {
    padding: 0;
  }
} // end <pre>

// -----------------------------------
// ## CODE => :not(pre)
:not(pre) > code,
:not(pre) > kbd,
:not(pre) > samp {
  color: $atom-mono-1;
  background: $atom-base;
}

// -----------------------------------
// ## CODE => GoHugo.io Gist Shortcode
.gist {

  // remove border-bottom link style from gist links
  & a { &:before, &:after { content: none; } }

  // remove border-radius from items
  & .gist-file, & .gist-data, & .gist-meta {
    border-radius: 0 !important;
  }

  & .gist-file {
    box-shadow: 0 2px 8px rgba(0,0,0,0.08);
  }

  & .gist-data {
    padding: 20px 4px;
  } // end gist-data

  & .gist-meta {
    & a {
      color: $color-accent !important;

      &:hover, &:focus {
        color: #000 !important;
      }
    } // end a
  } // end gist-meta
} // end gist
